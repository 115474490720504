import { META_ROBOTS_NOINDEX_NOFOLLOW } from '@configs/seo';

export const buildSeoForSearch = ({
    $t,
    store,
    total,
    limit,
    hasSuggested,
}) => {
    const { query } = store.state.category;

    const totalPages = Math.ceil(total / limit);

    const alternativePhraseText = $t(
        'Unfortunately we do not have {phrase}, but maybe you will be inspired by',
        { phrase: `"${query}"` }
    );

    const heading = hasSuggested
        ? alternativePhraseText
        : `${$t('Searching for phrase:')} '${query}'`;

    const seoMeta = {
        robots: META_ROBOTS_NOINDEX_NOFOLLOW,
        title: heading,
        description: [
            heading,
            $t('Top brands and top manufacturers from #MODIVOFASHION'),
            $t('Online fashion at MODIVO'),
        ].join(' | '),
    };

    return { totalPages, seoMeta, heading };
};
