import { SYNERISE_UUID_COOKIE_NAME } from '@configs/storage';
import { SYNERISE_SEARCH_TYPE_FULL_TEXT } from '@search/assets/search-service';

export const buildSearchDetails = ({ $cookies, searchQuery }) => {
    const visitorId = $cookies.get(SYNERISE_UUID_COOKIE_NAME);

    const searchEngineOffset =
        searchQuery.search_engine_offset ??
        (searchQuery.page - 1) * searchQuery.limit;

    const searchDetails = {
        correlationId: searchQuery.synerise_correlation_id,
        clientUUID: visitorId,
        searchType: SYNERISE_SEARCH_TYPE_FULL_TEXT,
    };

    return { searchDetails, searchEngineOffset };
};
