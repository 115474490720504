import memoryCache from 'memory-cache';

import getMemoryCacheKey from '@modules/m1-migration/redirector/get-memory-cache-key';
import {
    MEMORY_CACHE_REDIRECTS_KEY_PREFIX,
    MEMORY_CACHE_FILTERS_KEY_PREFIX,
    MEMORY_CACHE_FILTERS_PER_CATEGORY_KEY_PREFIX,
} from '@modules/m1-migration/redirector/memory-cache-prefix';

export default class Redirector {
    static FILTER_NAME_VALUE_DELIMITER = ':';
    static FILTER_VALUES_DELIMITER = '-';
    static FILTERS_DELIMITER = '/';
    static SUBCATEGORY_DELIMITER = '/';
    static URL_SUFFIX = '.html';
    static M1_PRODUCT_PAGE_REGEX = {
        // eslint-disable-next-line max-len
        uk_UA: /^(?!unisex|sport|aksesuari|sumki|ditjache|choloviche|zhinoche)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        lt_LT: /^(?!unisex|sportiniai-batai|aksesuarai|rankines|vaikams|vyrams|moterims)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        ru_UA: /^(?!unisex|sport|aksessuary|sumochki|detskaja|muzhskaja|zhenskaja)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        hr_HR: /^(?!unisex|sport|dodatci|torbe|djecja-obuca|muska-obuca|zenska-obuca)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        el_GR: /^(?!unisex|atlitika|axesoyar|tsantes|paidika|andrika|gynaikeia)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        es_ES: /^(?!unisex|deportes|complementos|bolsos|ninos|hombre|mujer)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        ro_RO: /^(?!unisex|sport|accesorii|posete|copii|barbati|dama)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        bg_BG: /^(?!unisex|sport|aksesoari|chanti|detski|m-zhki|damski)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        sv_SE: /^(?!unisex|sportskor|accessoarer|handvaskor|barnskor|herrskor|damskor)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        fr_FR: /^(?!unisex|chaussures-de-sport|accessoires|sacs|enfant|homme|femme)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        pl_PL: /^(?!unisex|sport|akcesoria|torebki|dzieciece|meskie|damskie)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        cs_CZ: /^(?!unisex|sport|doplnky|kabelky|detske|panske|damske)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        de_DE: /^(?!unisex|sport|zubehor|handtaschen|kinderschuhe|herrenschuhe|damenschuhe)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        hu_HU: /^(?!unisex|sportcipok|kiegeszitok|taskak|gyermek|ferfi|noi)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        sk_SK: /^(?!unisex|sport|doplnky|kabelky|detske|panske|damske)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        it_IT: /^(?!unisex|sport|accessori|borse|bambino|uomo|donna)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        de_CH: /^(?!unisex|sport|zubehor|handtaschen|kinderschuhe|herrenschuhe|damenschuhe)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        fr_CH: /^(?!unisexe|chaussures-de-sport|accessoires|sacs|enfant|homme|femme)\/[a-z0-9-]{20,}.html/,
        // eslint-disable-next-line max-len
        it_CH: /^(?!unisex|sport|accessori|borse|bambino|uomo|donna)\/[a-z0-9-]{20,}.html/,
    };

    static M1_CATEGORY_PAGE_REGEX = {
        uk_UA: /\/(unisex|sport|aksesuari|sumki|ditjache|choloviche|zhinoche)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        lt_LT: /\/(unisex|sportiniai-batai|aksesuarai|rankines|vaikams|vyrams|moterims)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        ru_UA: /\/(unisex|sport|aksessuary|sumochki|detskaja|muzhskaja|zhenskaja)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        hr_HR: /\/(unisex|sport|dodatci|torbe|djecja-obuca|muska-obuca|zenska-obuca)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        el_GR: /\/(unisex|atlitika|axesoyar|tsantes|paidika|andrika|gynaikeia)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        es_ES: /\/(unisex|deportes|complementos|bolsos|ninos|hombre|mujer)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        ro_RO: /\/(unisex|sport|accesorii|posete|copii|barbati|dama)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        bg_BG: /\/(unisex|sport|aksesoari|chanti|detski|m-zhki|damski)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        sv_SE: /\/(unisex|sportskor|accessoarer|handvaskor|barnskor|herrskor|damskor)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        fr_FR: /\/(unisex|chaussures-de-sport|accessoires|sacs|enfant|homme|femme)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        cs_CZ: /\/(unisex|sport|doplnky|kabelky|detske|panske|damske)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        pl_PL: /\/(unisex|sport|akcesoria|torebki|dzieciece|meskie|damskie)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        de_DE: /\/(unisex|sport|zubehor|handtaschen|kinderschuhe|herrenschuhe|damenschuhe)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        hu_HU: /\/(unisex|sportcipok|kiegeszitok|taskak|gyermek|ferfi|noi)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        sk_SK: /\/(unisex|sport|doplnky|kabelky|detske|panske|damske)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        it_IT: /\/(unisex|sport|accessori|borse|bambino|uomo|donna)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        de_CH: /\/(unisex|sport|zubehor|handtaschen|kinderschuhe|herrenschuhe|damenschuhe)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        fr_CH: /\/(unisexe|chaussures-de-sport|accessoires|sacs|enfant|homme|femme)[a-z0-9-/_:]*.html/,
        // eslint-disable-next-line max-len
        it_CH: /\/(unisex|sport|accessori|borse|bambino|uomo|donna)[a-z0-9-/_:]*.html/,
    };

    static M1_ONE_FILTER_REGEX = /^[^:]+:[^:]+$/;

    // eslint-disable-next-line max-len
    static M1_NEWSLETTER_UNSUBSCRIBE_BY_TOKEN_PAGE_REGEX = /^\/newsletter\/subscriber\/unsubscribeall(\/)?$/;

    static isM1ProductPage(url = '', locale = '') {
        const M1ProductPageRegex = Redirector.M1_PRODUCT_PAGE_REGEX[locale];

        if (!M1ProductPageRegex) {
            return false;
        }

        return Redirector.M1_PRODUCT_PAGE_REGEX[locale].test(decodeURI(url));
    }

    static isM1CategoryPage(url = '', locale = '') {
        return Redirector.M1_CATEGORY_PAGE_REGEX[locale].test(decodeURI(url));
    }

    static isM1CategoryPageWithOneFilter(url = '', locale = '') {
        return (
            Redirector.isM1CategoryPage(url, locale) &&
            Redirector.M1_ONE_FILTER_REGEX.test(decodeURI(url))
        );
    }

    static isM1UnsubscribeByTokenNewsletterPage(url = '') {
        return Redirector.M1_NEWSLETTER_UNSUBSCRIBE_BY_TOKEN_PAGE_REGEX.test(
            decodeURI(url)
        );
    }

    static isM1InviteFriendUrl(queryString = '') {
        if (!queryString) {
            return false;
        }

        const M1_STORE_QUERY_PARAM_NAME = '__store';
        const M1_REF_QUERY_PARAM_NAME = 'ref';

        return [M1_REF_QUERY_PARAM_NAME, M1_STORE_QUERY_PARAM_NAME].every(key =>
            queryString.includes(key)
        );
    }

    static getProductSlugFromUrl(url = '') {
        return url.replace('/', '').replace(Redirector.URL_SUFFIX, '');
    }

    static getCategoryFiltersFromUrl(
        currentUrl = '',
        categoryUrl = '',
        locale = ''
    ) {
        const availableFiltersInCategory =
            Redirector.getFiltersPerCategoryForLocale(locale, categoryUrl) ||
            [];

        const segments = currentUrl
            .replace(Redirector.URL_SUFFIX, '')
            .split(Redirector.FILTERS_DELIMITER);

        const filters = Redirector.getFiltersForLocale(locale) || {};

        return segments
            .filter(segment => {
                return segment.includes(Redirector.FILTER_NAME_VALUE_DELIMITER);
            })
            .reduce((acc, filter) => {
                const newFilter = filters[filter] || '';

                if (!newFilter) {
                    return acc;
                }

                const newFilterSlug = newFilter.split(
                    Redirector.FILTER_NAME_VALUE_DELIMITER
                )[0];

                if (!availableFiltersInCategory.includes(newFilterSlug)) {
                    return acc;
                }

                const [filterName, filterValue] = newFilter.split(
                    Redirector.FILTER_NAME_VALUE_DELIMITER
                );

                acc[filterName] = filterValue.split(
                    Redirector.FILTER_VALUES_DELIMITER
                );

                return acc;
            }, {});
    }

    static getCategorySlugFromUrl(url = '') {
        return url
            .split(Redirector.SUBCATEGORY_DELIMITER)
            .filter(
                item =>
                    !!item &&
                    !item.includes(Redirector.FILTER_NAME_VALUE_DELIMITER)
            )
            .join(Redirector.SUBCATEGORY_DELIMITER)
            .replace(Redirector.URL_SUFFIX, '');
    }

    static getMemoryCacheRedirectsKeyForLocale(locale = '') {
        return getMemoryCacheKey(locale, MEMORY_CACHE_REDIRECTS_KEY_PREFIX);
    }

    static getMemoryCacheFiltersKeyForLocale(locale = '') {
        return getMemoryCacheKey(locale, MEMORY_CACHE_FILTERS_KEY_PREFIX);
    }

    static getMemoryCacheFiltersPerCategoryKeyForLocale(locale = '') {
        return getMemoryCacheKey(
            locale,
            MEMORY_CACHE_FILTERS_PER_CATEGORY_KEY_PREFIX
        );
    }

    static shouldCheckRedirects(locale = '') {
        return memoryCache
            .keys()
            .includes(this.getMemoryCacheRedirectsKeyForLocale(locale));
    }

    static getRedirectsForLocale(locale = '') {
        return (
            memoryCache.get(
                Redirector.getMemoryCacheRedirectsKeyForLocale(locale)
            ) || {}
        );
    }

    static getFiltersForLocale(locale = '') {
        return (
            memoryCache.get(
                Redirector.getMemoryCacheFiltersKeyForLocale(locale)
            ) || {}
        );
    }

    static getFiltersPerCategoryForLocale(locale = '', categoryUrl = '') {
        const filtersPerCategoryForLocale =
            memoryCache.get(
                Redirector.getMemoryCacheFiltersPerCategoryKeyForLocale(locale)
            ) || {};

        return filtersPerCategoryForLocale[categoryUrl] || [];
    }

    static getCategoryM2Redirect(locale, url) {
        if (!Redirector.shouldCheckRedirects(locale)) {
            return;
        }

        return Redirector.getRedirectsForLocale(locale)[url];
    }
}
