export default [
    'absatzhohe',
    'acabado_de_caja',
    'adeia',
    'adiabrokhopoiese_1',
    'a_futo_testsulya',
    'aizdare',
    'akcesoria_biegowe',
    'akcja',
    'aldersgrupp',
    'alla',
    'allithato_meret',
    'altele',
    'alter',
    'altezza_tacco',
    'altro',
    'altura_de_tacon',
    'amzius',
    'ancho_del_zapato',
    'andamal',
    'andamal_accessoarer',
    'andere',
    'annat',
    'antal_hal',
    'antal_stora_fickor',
    'anzahl_der_facher',
    'anzahl_der_osen',
    'apava_platums',
    'apavu_izmers',
    'apgeba_materials',
    'aplosnes_izstrade',
    'apsauga_nuo_uv_spinduliu',
    'ar',
    'arithmos_opon',
    'arithmos_thekon',
    'armel',
    'art_der_fullung',
    'arten_von_handschuhen',
    'arten_von_mutzen',
    'athlemata',
    'athletika',
    'atsparumas_vandeniui',
    'autres',
    'avalynes_dydis',
    'bagform',
    'baros_tou_dromea',
    'bato_plotis',
    'begiko_svoris',
    'belelt',
    'bestimmung_accessoires',
    'bestimmung_mittel',
    'biksu_staru_stils',
    'bildschirmdiagonale',
    'boettens_material',
    'boritektaska_anyaga',
    'bretea',
    'bretelle',
    'bretelles',
    'broi_dupki',
    'broi_otdeleniia',
    'broj_pretinaca',
    'broj_rupica',
    'bukle',
    'cadran_confectionat_din',
    'cantidad_de_agujeros',
    'cappuccio',
    'capuche',
    'carryover',
    'cauruminu_skaits',
    'cena',
    'cepures_veids',
    'chaussures_de_sport',
    'cijena',
    'cimdu_veids',
    'cipomeret',
    'cipo_szelessege',
    'citi',
    'composition_bijoux',
    'confectionarea_bijuteriei',
    'confezione_multipla',
    'coupe_de_pantalon',
    'createur',
    'csapagy_tipusa',
    'danga',
    'delka',
    'delninukes_medziaga',
    'desen',
    'designer',
    'destination',
    'destinat_pentru_accesoriu',
    'destinat_pentru_preparate',
    'destinazione_duso_dellaccessorio',
    'destinazione_duso_del_prodotto',
    'diagonala_ecran',
    'diagonala_zaslona',
    'diagonal_de_pantalla',
    'diagonale_de_lecran',
    'diagonale_dello_schermo',
    'diagonios_othones',
    'diametre_des_roues',
    'diametr_na_kolelata',
    'diametro_delle_ruote',
    'diametro_de_ruedas',
    'diametros_trokhon',
    'diametrul_rotilor',
    'dijagonala_zaslona',
    'dimensioni_di_borsa',
    'dimensioni_zaino',
    'dimenzije_ruksaka',
    'disciplinas_deportivas',
    'discipline_sportive',
    'disciplines_sportives',
    'disenador',
    'diseno',
    'distance_hebdomadaire',
    'distancia_semanal',
    'distanta_saptamanala',
    'distanza_settimanale',
    'dizainer',
    'dizaineris',
    'dizajner',
    'dlugosc',
    'dlzhina',
    'dlzka',
    'dobni_raspon',
    'dolzina_oblacil',
    'doublure',
    'doublure_1',
    'drugi',
    'drugo',
    'drugo_1',
    'druh_podrazky',
    'druh_vyplne',
    'durchmesser_der_rollen',
    'duzina',
    'dyscypliny_sportowe',
    'dystans_tygodniowy',
    'ebdomadiaia_apostase',
    'eco_friendly',
    'egyeb',
    'eidos_ependuses',
    'eidos_podiou',
    'eidos_solas',
    'einstellbare_grosse',
    'ekrana_diagonale',
    'ekrano_istrizaine',
    'ekszeres_kivitelezes',
    'epiphaneia',
    'etancheite',
    'etanseitate',
    'euros_elikias',
    'fascia_deta',
    'felulet_tipus',
    'fermeture',
    'fermeture_2',
    'filtre_polarisant',
    'filtre_uv',
    'filtro_polarizador',
    'filtro_polarizzatore',
    'filtro_uv',
    'filtr_polaryzacyjny',
    'filtru_de_polarizare',
    'filtru_uv',
    'filtr_uv',
    'finish',
    'finitura_dei_gioielli',
    'fonction',
    'forma_de_monturas',
    'forma_montatura',
    'forma_na_ramki',
    'forma_rama',
    'form_der_fassung',
    'forme_de_monture',
    'forro_termico',
    'fottyper',
    'funcion',
    'functie',
    'funkce',
    'funkcia',
    'funkcija',
    'funkcio',
    'funkcja',
    'funktion',
    'funktsiia',
    'funzione',
    'fusstyp',
    'futterung',
    'gamyba_1',
    'garums',
    'gaubtas',
    'gehausematerial',
    'gewicht_des_laufers',
    'gluga',
    'golemina_na_chanta',
    'greutatea_alergatorului',
    'gultnu_veids',
    'guolio_rusis',
    'hatizsak_merete',
    'hauteur_de_talon',
    'heti_tavolsag',
    'hjulens_diameter',
    'hmotnost_bezca',
    'hosszusag',
    'idioteta',
    'idropellenza',
    'ietvaru_forma',
    'ilgis',
    'ilosc_dziurek',
    'il_set_include',
    'imbottitura',
    'impermeabilita',
    'impermeabilitate_1',
    'impermeabilite',
    'impermeable',
    'impermeable_1',
    'imprimeu',
    'im_set',
    'inaltimea_tocului',
    'incalzire',
    'inchizatoare_imbracaminte',
    'inclus_dans_le_set',
    'incluye',
    'ine',
    'ingar_i_setet',
    'inne',
    'interval_de_varsta',
    'izdelava_nakita',
    'izdelava_ohisja',
    'izrabotka_na_bizhuteriiata',
    'jine',
    'justerbar_storlek',
    'kabalos',
    'kachulka',
    'kaina',
    'kalup',
    'kantran_uliko',
    'kaptur',
    'kapuca',
    'kapuce',
    'kapucna',
    'kapucni',
    'kapuljaca',
    'kapuze',
    'kar',
    'kepatlo',
    'kepures_rusis',
    'kerekek_atmeroje',
    'keret_formaja',
    'kesztyu_fajta',
    'kiti',
    'klackhojd',
    'klesniu_modelis',
    'komplekta',
    'korosztaly',
    'koukoula',
    'koumpoma',
    'krepsio_ismatavimai',
    'ksztalt_oprawek',
    'kulno_aukstis',
    'kuprines_dydis',
    'lab_tipusa',
    'lagertyp',
    'langd',
    'lange',
    'largeur_de_la_chaussure',
    'larghezza_della_scarpa',
    'last_1',
    'latimea_pantofului',
    'lencite',
    'licenca',
    'licence',
    'licencia',
    'licencija',
    'licencja',
    'licencovany_produkt',
    'licensierad_produkt',
    'licensz_termek',
    'licenta',
    'licenza',
    'liczba_komor',
    'lietosanas_merkis_aksesuari',
    'lietosanas_merkis_preparati',
    'litsenz',
    'lizenzprodukt',
    'longitud',
    'longueur',
    'loparens_vikt',
    'lunghezza',
    'lungime',
    'lyukak_szama',
    'manche',
    'maneca',
    'manica',
    'maniki',
    'marimea_gentii',
    'marimea_rucsacului',
    'marime_incaltaminte',
    'marime_reglabila',
    'material',
    'material_de_la_joya',
    'materiale_della_cassa',
    'material_imbracaminte',
    'material_klader',
    'material_na_korpusa',
    'material_oblacila',
    'material_obleceni',
    'material_oblecenia',
    'material_odziez',
    'materiau_du_boitier',
    'materiaux',
    'materiaux_2',
    'materiia',
    'materijal_kucista',
    'materijal_nakita',
    'materijal_odjece',
    'medziaga_drabuziai',
    'megethos_papoutsion',
    'megethos_sakidiou_plates',
    'megethos_sakou',
    'mekos',
    'mintazat',
    'monster',
    'motibo',
    'motif',
    'mpatzaki',
    'mugursomas_izmers',
    'multipack',
    'multipak',
    'multipaka',
    'multipaket',
    'multipakiranje',
    'muster',
    'nadrag_stilus',
    'namen_dodatki',
    'namen_uporabe_preparati',
    'namjena_modni_dodaci',
    'namjena_preparati',
    'naramenica',
    'naramnica',
    'nastavitelna_velikost',
    'nastavitelna_velkost',
    'nastavljiva_velikost',
    'navrhar',
    'nawierzchnia',
    'nedelas_distance',
    'neperslampamumas',
    'nepraleidzia_vandens',
    'nepremokavost',
    'neprepustnost',
    'nepromokaemost',
    'nepromokavost',
    'nodalijumu_skaits',
    'nombre_de_compartiments',
    'nombre_de_trous',
    'nosenje',
    'numar_compartimente',
    'numar_gauri',
    'numero_de_compartimentos',
    'oblika_okvirja',
    'oblik_okvira',
    'oblikovalec',
    'ocieplenie',
    'okolju_prijazen',
    'osszetetel_1',
    'otopliane',
    'otros',
    'pado_tipas',
    'pant',
    'papeza_augstums',
    'pas',
    'pasiltinimas',
    'paskirtis_aksesuarai',
    'paskirtis_preparatai',
    'pedas_tips',
    'pedos_tipas',
    'peso_corritore',
    'peso_del_corredor',
    'petneseles',
    'philtro_uv',
    'piedurkne',
    'pildijuma_veids',
    'pirstiniu_rusis',
    'platos_papoutsiou',
    'pocet_dierok',
    'pocet_direk',
    'pocet_komor',
    'podloga',
    'podloga_2',
    'podstava_1',
    'poids_du_coureur',
    'pointure',
    'polarisationsfilter',
    'polariserande_glas',
    'polarizacijas_filtrs',
    'polarizacijski_filter',
    'polarizacni_filtr',
    'polarizacny_filter',
    'polarizalt_szuro',
    'poliarizatsionen_filtr',
    'poliarizuoti',
    'polotiko_philtro',
    'pouzite',
    'pouzitie',
    'povrch',
    'povrkhnost',
    'precio',
    'prednaznachenie_aksesoari',
    'prednaznachenie_preparati',
    'preis',
    'premer_koles',
    'pret',
    'prezramki',
    'prezzo',
    'priemer_koliesok',
    'priilagodljiva_velicina',
    'print',
    'pris',
    'prix',
    'productos',
    'produktart',
    'produkta_veids',
    'produkto_tipas',
    'proiectant',
    'projektant',
    'promjer_kotaca',
    'proorismos_axesouar',
    'proorismos_paraskeuasmata',
    'provedeni_bizuterie',
    'provedeni_pouzdra',
    'prumer_kolecek',
    'przekatna_ekranu',
    'przeznaczenie_akcesoria',
    'przeznaczenie_preparaty',
    'quantita_di_occhielli',
    'quantita_di_scomparti',
    'raksts',
    'ramiaczka',
    'ramienka',
    'raminka',
    'rango_de_edad',
    'rankoves',
    'rastas',
    'ratuku_skersmuo',
    'razmer_na_ekrana_po_diagonal',
    'razmer_na_obuvki',
    'razmer_na_ranitsata',
    'regulejams_izmers',
    'reguliruem_razmer',
    'reguliuojamas_dydis',
    'regulowany_rozmiar',
    'rekaw',
    'rekeszek_szama',
    'remeliu_forma',
    'rendeltetes',
    'rendeltetes_kiegeszito',
    'resistencia_al_agua_estanco',
    'rezistenta_la_apa',
    'rinkinyje',
    'ritenisu_diametrs',
    'rivestimento_1',
    'rkav',
    'rodzaj_lozyska',
    'rodzaj_podeszwy',
    'rodzaj_wypelnienia',
    'rogzites',
    'rokavi',
    'rotaslietu_izstrade',
    'rozmiar_obuwia',
    'rozmiar_plecaka',
    'rozmiar_torby',
    'rucksackgrosse',
    'ruhazat_anyaga',
    'rukav',
    'rukavi',
    'ruthmizomeno_megethos',
    'ryggsacksmatt',
    'saison',
    'sapka_fajta',
    'sarok_magassaga',
    'sasong',
    'savaites_atstumas',
    'schmuckausfuhrung',
    'schuhbreite',
    'schuhgrosse',
    'season_1',
    'sedmichna_distantsiia',
    'segums',
    'set_pollon_temakhion',
    'setul_contine',
    'sezon',
    'sezona',
    'sezonas',
    'shirina_na_obuvkata',
    'siltinajums',
    'sirina_cevlja',
    'sirka_boty',
    'sirka_topanky',
    'skarmens_diagonal',
    'skhediastes',
    'skhema_skeletou',
    'skostorlek',
    'skrejeja_svars',
    'skyluciu_kiekis',
    'skyriu_kiekis',
    'sohlenart',
    'somas_izmers',
    'sport',
    'sporta_disciplinas',
    'sportagak',
    'sportarten',
    'sportcipok',
    'sporter',
    'sportiniai_batai',
    'sportne_discipline',
    'sportni_distsiplini',
    'sporto_sakos',
    'sportove_discipliny',
    'sportovni_discipliny',
    'sports',
    'sportske_discipline',
    'sportskor',
    'spracovanie_bizuterie',
    'srednica_kol',
    'stagione',
    'stagione_1',
    'stan',
    'stanje',
    'starostno_obdobje',
    'stav',
    'stavoklis',
    'stevilo_gumbnic',
    'stevilo_predalov',
    'stile_gambe_pantalone',
    'stilista',
    'stil_na_krachola',
    'stil_nogavic',
    'stil_nogavica',
    'stilul_picioarelor',
    'stoff',
    'struk',
    'style_der_hosenbeine',
    'styl_nogawek',
    'styl_nohavic',
    'sultyp',
    'suprafata',
    'szabas',
    'szerokosc_buta',
    'szettben',
    'szezon',
    'taglia_regolabile',
    'taglia_scarpe',
    'taille',
    'taille_ajustable',
    'taille_de_sac',
    'taille_du_sac_a_dos',
    'talie',
    'taliia',
    'talla_de_la_mochila',
    'talla_de_zapato',
    'talp_tipusa',
    'tamano_ajustable',
    'tamano_de_bolso',
    'taschengrosse',
    'taska_merete_2',
    'teglo_na_begacha',
    'temporada',
    'tenuta_stagna',
    'termek_tipusa',
    'terreno_di_corsa',
    'tervezo',
    'tessuto',
    'tessuto_1',
    'tezina_trkaca',
    'thermomonose',
    'time',
    'tip_izdelka',
    'tip_kape',
    'tipo_de_gorro',
    'tipo_de_guantes',
    'tipo_de_producto',
    'tipo_de_relleno',
    'tipo_de_rodamiento',
    'tipo_de_terreno',
    'tipo_di_chiusura',
    'tipo_di_cuscinetto',
    'tipo_di_piede',
    'tipo_di_prodotto',
    'tipo_di_suola',
    'tipologia_di_cappello',
    'tipologia_di_guanti',
    'tipos_de_pisada',
    'tipos_de_suela',
    'tip_potplata',
    'tip_produs',
    'tip_rukavica',
    'tip_stopala',
    'tipul_de_caciula',
    'tipul_de_manusa',
    'tipul_de_rulment',
    'tipul_de_umplutura',
    'tipul_piciorului',
    'tipul_talpii',
    'tirantes',
    'tjedna_udaljenost',
    'to_set_perilambanei',
    'trager',
    'tranche_dage',
    'tsena',
    'tupos_gantion',
    'tupos_proiontos',
    'tupos_rouleman',
    'tupos_skouphou_kapelou',
    'tvar_obroucek',
    'tvar_ramu',
    'tydenni_vzdalenost',
    'typ_av_fyllning',
    'typ_av_handskar',
    'typ_av_kullager',
    'typ_av_mossa',
    'typ_av_produkt',
    'typ_cepice',
    'typ_chodidla',
    'typ_ciapky',
    'typ_czapki',
    'type_de_bonnet',
    'type_de_gants',
    'type_de_pied',
    'type_de_produit',
    'type_de_rembourrage',
    'type_de_roulement',
    'type_de_semelle',
    'type_de_semelle_1',
    'type_de_surface',
    'type_de_taille',
    'typ_loziska',
    'typ_podrazky',
    'typ_produktu',
    'typ_rekawiczek',
    'typ_rukavic',
    'typ_stopy',
    'tyzdenna_vzdialenost',
    'udatosteganoteta',
    'udens_izturiba',
    'udensizturigums',
    'udensnecaurlaidiba',
    'uhlopricka_displeje',
    'uhlopriecka_disleja',
    'u_kompletu',
    'uliko',
    'underlag',
    'untergrund',
    'uphasma',
    'upsos_takouniou',
    'urceni_doplnek',
    'urcenie_doplnku',
    'uso_cuidado_del_calzado',
    'utilisation',
    'uv_filter',
    'uv_filtr',
    'uv_filtrs',
    'uv_skydd',
    'uv_szuro',
    'uzorak',
    'uzpildas',
    'uzsegimas',
    'vaha_bezce',
    'varmfodrat',
    'vaskstorlek',
    'vattentathet',
    'vattentathet_1',
    'vattentathet_2',
    'vecko_distans',
    'vecums',
    'vek',
    'velicina_obuce',
    'velicina_torbe_1',
    'velikost_batohu',
    'velikost_bot',
    'velikost_nahrbtnika',
    'velikost_obutve',
    'velikost_tasky',
    'velikost_torbe',
    'velkost_ruksaku',
    'velkost_tasky',
    'velkost_topanok',
    'verschluss',
    'vid_na_lagera',
    'vid_na_podplatata',
    'vid_na_produkta_1',
    'vid_na_rkavitsite',
    'vid_na_shapkata',
    'vidove_podmetki',
    'vid_stpalo',
    'visina_pete_1',
    'visina_potpetice',
    'visochina_na_tok',
    'vita',
    'vizallo',
    'vizallosag',
    'vizhatlan_1',
    'v_komplekta',
    'v_kompletu',
    'vodeodolnost',
    'vodonepromokaemost',
    'vodonepropusnost',
    'vodoodporan',
    'vodoodpornost',
    'vodootpornost',
    'vodotesnost',
    'vodoustoichivost',
    'vrsta_kape',
    'vrsta_lezaja',
    'vrsta_podplata',
    'vrsta_polnila',
    'vrsta_povrsine',
    'vrsta_proizvoda',
    'vrsta_punjenja',
    'vrsta_rokavic',
    'vrsta_stopala',
    'v_sade',
    'vyhotovenie_plasta_hodiniek',
    'vyska_podpatku',
    'vzor',
    'vzorec',
    'vzrastov_diapazon',
    'waga_biegacza',
    'wasserbestandigkeit',
    'wasserdicht',
    'wasserdichte',
    'waterproof',
    'wochentliche_distanz',
    'wodoodpornosc',
    'wodoodpornosc_2',
    'wodoszczelnosc',
    'wykonanie_bizuterii',
    'wykonanie_koperty',
    'wysokosc_obcasa',
    'w_zestawie',
    'wzor',
    'zakopcavanje',
    'zakopchavane',
    'zapatillas_deportivas',
    'zapenjanje',
    'zapiecie',
    'zapiecie_odziez',
    'zapinani',
    'zapinanie',
    'zatepleni',
    'zateplenie',
    'zoles_veids',
];
