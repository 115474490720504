import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const PLACEMENT = 'LISTING';

export const prepareAdsDisplayData = async ({
    $services,
    $errorHandler,
    $ua,
    store,
    route,
}) => {
    if (process.server && $ua?.isBot) {
        return { topListingDisplayData: {}, crossListingDisplayData: {} };
    }

    const {
        category: { query, url },
        config: {
            storeView: { locale },
        },
    } = store.state;

    try {
        const adsDisplayData = await $services.adTech.getDisplayWithPositions({
            payload: {
                placement: PLACEMENT,
                categoryPath: url,
                sourceUrl: route.fullPath,
                query: query ?? '',
                limit: 2,
            },
            locale,
        });

        const {
            display: {
                TOP: topListingDisplayData = {},
                MIDDLE: crossListingDisplayData = {},
            } = {},
        } = adsDisplayData || {};

        return { topListingDisplayData, crossListingDisplayData };
    } catch (err) {
        $errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'catalog.getDisplay',
        });

        return { topListingDisplayData: {}, crossListingDisplayData: {} };
    }
};
