import { createNamespacedHelpers } from 'vuex';

import { IS_OMNIBUS_STRICT_ENABLED } from '@localeConfig/keys';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    computed: {
        ...mapConfigGetters(['localeConfigByKey']),

        isOmnibusStrictEnabled() {
            return this.localeConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        },
    },
};
