import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

import Redirector from '@modules/m1-migration/redirector/Redirector';
import { makeRedirect } from '@modules/m1-migration/redirector/utils';

import CategoryNotFoundException from '@exceptions/services/category/CategoryNotFoundException';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareProductsCatalogData = async ({
    $asyncServices,
    $errorHandler,
    route,
    req,
    requestQuery,
    redirect,
    searchRequestParams,
}) => {
    const { data, error, status, originalResponse } = await $asyncServices.use(
        CATALOG_SERVICE_NAME,
        CATALOG_SERVICE_METHODS.GET_PRODUCTS,
        searchRequestParams
    );

    if (data === null && process.server) {
        const redirectUrl = Redirector.getCategoryM2Redirect(
            searchRequestParams.locale,
            route.path
        );

        if (redirectUrl) {
            return makeRedirect(req, redirect, redirectUrl, requestQuery);
        }
    }

    if (!error && data) {
        const {
            products: catalogProducts = [],
            search_query: searchQuery = {},
            sort_options: sortOptions = [],
            total = null,
            totalFormatted = null,
        } = data;

        return {
            catalogProducts,
            searchQuery,
            sortOptions,
            total,
            totalFormatted,
        };
    }

    const errorToThrow = new Error(error?.message || 'UNKNOWN_ERROR', {
        cause: error,
    });

    $errorHandler.captureError(
        errorToThrow,
        {
            [ERROR_ACTION_TAG_NAME]: 'getCatalogListingData',
        },
        {
            requestParams: searchRequestParams,
            data,
            error,
            status,
            originalResponse,
        }
    );

    if (status === NOT_FOUND_HTTP_CODE) {
        throw new CategoryNotFoundException(
            JSON.stringify(searchRequestParams)
        );
    }

    throw errorToThrow;
};
