import {
    NUMBER_OF_PRODUCTS_BETWEEN_SPONSORED,
    HALF_OF_SPONSORED_PRODUCTS,
    MAXIMUM_OF_SPONSORED_PRODUCTS,
} from '@configs/ad-tech';

export function placeSponsoredProducts({ products, sponsoredProducts }) {
    if (!sponsoredProducts.length) {
        return products;
    }

    return [
        ...sponsoredProducts.slice(0, HALF_OF_SPONSORED_PRODUCTS),
        ...products.slice(0, NUMBER_OF_PRODUCTS_BETWEEN_SPONSORED),
        ...sponsoredProducts.slice(
            HALF_OF_SPONSORED_PRODUCTS,
            MAXIMUM_OF_SPONSORED_PRODUCTS
        ),
        ...products.slice(
            NUMBER_OF_PRODUCTS_BETWEEN_SPONSORED,
            products.length
        ),
    ];
}
