<template>
    <WithProductImpressionViewEvent
        :products="products"
        :current-page="currentPage"
        :is-page-event-emitted-deferred="isPageEventEmittedDeferred"
        :products-per-page="productsPerPage"
        :view-placement-event-urls="viewPlacementEventUrls"
        :attribution-token="attributionToken"
        :search-engine-offset="searchEngineOffset"
    >
        <ProductsListWithDisplay
            :products="products"
            :products-color-variants="productsColorVariants"
            :is-product-link-disabled="true"
            :cross-listing-display="crossListingDisplay"
            :attribution-token="attributionToken"
            @product-click="onProductClick($event)"
            @product-open-contextmenu="onProductOpenContextMenu($event)"
        />
    </WithProductImpressionViewEvent>
</template>

<script>
import {
    LEFT_CLICK,
    LEFT_CTRL_CLICK,
    RIGHT_CLICK,
    MIDDLE_CLICK,
} from '@configs/ad-tech';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';
import { CATEGORY_PRODUCT_CARD_OPEN_CONTEXTMENU } from '@analytics-modules/catalog/types/Events';

import WithProductImpressionViewEvent from '@molecules/WithProductImpressionViewEvent/WithProductImpressionViewEvent';

import ProductsListWithDisplay from '@search-organisms/ProductsList/ProductsListWithDisplay';

export default {
    name: 'ProductsListing',

    components: {
        ProductsListWithDisplay,
        WithProductImpressionViewEvent,
    },

    props: {
        isPageEventEmittedDeferred: {
            type: Object,
            required: true,
        },

        products: {
            type: Array,
            default: () => [],
        },

        productsColorVariants: {
            type: Object,
            default: () => ({}),
        },

        crossListingDisplay: {
            type: Object,
            default: () => ({}),
        },

        productsPerPage: {
            type: Number,
            default: 0,
        },

        currentPage: {
            type: Number,
            default: 0,
        },

        viewPlacementEventUrls: {
            type: Array,
            default: () => [],
        },

        loadPlacementEventUrls: {
            type: Array,
            default: () => [],
        },

        attributionToken: {
            type: [String, null],
            default: null,
        },

        searchEngineOffset: {
            type: [Number, null],
            default: null,
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                this.loadPlacementEventUrls.forEach(beacon => {
                    this.$services.adTech.notifyBeacon({ beacon });
                });
            },
        },
    },

    methods: {
        onProductClick({
            mouseEvent: { ctrlKey = false, metaKey = false, middleKey = false },
            product,
            position,
        }) {
            if (!ctrlKey && !metaKey && !middleKey) {
                const path = !this.attributionToken
                    ? product.url
                    : `${product.url}?token=${this.attributionToken}`;

                this.$router.push({
                    path,
                });
            }

            /* eslint-disable no-nested-ternary, indent */
            const clickLabel = ctrlKey
                ? LEFT_CTRL_CLICK
                : middleKey
                ? MIDDLE_CLICK
                : LEFT_CLICK;
            /* eslint-enable no-nested-ternary, indent */

            this.$emit('product-click', {
                product,
                position,
                clickLabel,
            });
        },

        onProductOpenContextMenu({ product }) {
            this.$analytics.moduleEmit(
                CATALOG_MODULE_NAME,
                CATEGORY_PRODUCT_CARD_OPEN_CONTEXTMENU,
                {
                    url: product.url,
                }
            );

            this.$emit('product-right-click', {
                product,
                clickLabel: RIGHT_CLICK,
            });
        },
    },
};
</script>
