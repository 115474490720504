import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareFiltersData = async ({
    $asyncServices,
    $errorHandler,
    searchRequestParams,
}) => {
    const { data, error, status, originalResponse } = await $asyncServices.use(
        CATALOG_SERVICE_NAME,
        CATALOG_SERVICE_METHODS.GET_FILTERS,
        searchRequestParams
    );

    if (!error && data) {
        return data;
    }

    const errorToThrow = new Error(error?.message || 'UNKNOWN_ERROR', {
        cause: error,
    });

    $errorHandler.captureError(
        errorToThrow,
        {
            [ERROR_ACTION_TAG_NAME]: 'getCatalogFiltersData',
        },
        {
            requestParams: searchRequestParams,
            data,
            error,
            status,
            originalResponse,
        }
    );

    return { filters: {} };
};
