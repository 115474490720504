<template>
    <li
        :key="`${url}-${isActive ? 'static' : ''}`"
        :class="{ static: isActive || isDisabled }"
        class="category-navigation-tree-item"
    >
        <a :href="url" class="link">
            <CellFilterDetails
                :horizontal-padding="CELL_PADDING"
                :label="categoryLabel"
                :filter-pattern="filterPattern"
                :is-list-item="false"
            >
                <template #circle-digit="{ circleDigitVariant }">
                    <CircleDigit
                        :digit="productsCount"
                        :variant="circleDigitVariant"
                        :unlimited="true"
                    />
                </template>
            </CellFilterDetails>
        </a>

        <Pill
            :selected="isActive"
            :disabled="isDisabled"
            :href="url"
            class="pill-item"
        >
            {{ categoryLabel }}
            <template v-if="isActive" #icon>
                <Icon :width="16" :height="16" :icon="Tick" />
            </template>
        </Pill>
    </li>
</template>

<script>
import { QUERY_ORDER, QUERY_ORDER_DIR } from '@configs/query-keys/sort';
import {
    QUERY_SEARCH,
    QUERY_SEARCH_CATEGORY,
} from '@configs/query-keys/search';

import { stringifyCategoryUrl, stringifySearchUrl } from '@assets/path';

import { Tick } from '@eobuwie-ui/icons/v1/other';

import {
    CellFilterDetails,
    CELL_FILTER_DETAILS_PADDINGS,
} from '@eobuwie-ui/components/CellFilterDetails/v1';
import { CircleDigit } from '@eobuwie-ui/components/CircleDigit/v1';
import { Pill } from '@eobuwie-ui/components/Pill/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'CategoryNavigationTreeItem',

    components: {
        Pill,
        Icon,
        CellFilterDetails,
        CircleDigit,
    },

    props: {
        locale: {
            type: String,
            required: true,
        },

        category: {
            type: Object,
            required: true,
        },

        activeCategoryUrl: {
            type: String,
            required: true,
        },

        isSearchPage: {
            type: Boolean,
            default: false,
        },

        urlFilters: {
            type: Object,
            default: () => {},
        },
    },

    setup() {
        return {
            Tick,
            IS_CONTEXTMENU: true,
            CELL_PADDING: CELL_FILTER_DETAILS_PADDINGS.PX_0,
        };
    },

    computed: {
        categoryLabel() {
            return this.category.translations[this.locale].label;
        },

        filterPattern() {
            return this.isActive ? this.categoryLabel : '';
        },

        categoryUrl() {
            return this.category.translations[this.locale].url;
        },

        productsCount() {
            return this.category.productsCount;
        },

        isActive() {
            return this.categoryUrl === this.activeCategoryUrl;
        },

        isDisabled() {
            return !this.productsCount && !this.isActive;
        },

        url() {
            const { query } = this.$route;

            const queryToApply = {
                [QUERY_ORDER]: query[QUERY_ORDER],
                [QUERY_ORDER_DIR]: query[QUERY_ORDER_DIR],
            };

            let pathToApply = '';

            const filters = {
                ...this.$filterManager.localizedFilters,
                ...this.urlFilters,
            };

            if (this.isSearchPage) {
                const searchQuery = query[QUERY_SEARCH];

                queryToApply[QUERY_SEARCH] = searchQuery;
                queryToApply[QUERY_SEARCH_CATEGORY] = this.categoryUrl;

                pathToApply = `/s/${stringifySearchUrl({
                    query: encodeURIComponent(searchQuery),
                    filters,
                })}`;
            } else {
                pathToApply = stringifyCategoryUrl({
                    categoryUrl: this.$createCategoryPath(this.categoryUrl),
                    filters,
                });
            }

            const {
                route: { fullPath },
            } = this.$router.resolve({
                path: pathToApply,
                query: queryToApply,
            });

            return fullPath;
        },
    },
};
</script>

<style lang="scss" scoped>
.category-navigation-tree-item {
    @apply block;

    .pill-item.selected {
        @apply pointer-events-none;
    }

    @screen mobile-and-tablet-only {
        @apply whitespace-nowrap;

        &:has(.pill-item.selected) {
            @apply order-first;
        }

        .link {
            @apply hidden;
        }
    }

    @screen lg {
        .pill-item {
            @apply hidden;
        }

        &.static {
            @apply pointer-events-none;
        }
    }
}
</style>
