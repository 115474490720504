import Product from '@models/Catalog/Product';

import { CATALOG_NAMESPACE } from '@models/Performance/PerformanceRecorder';

const { REBUILD_PRODUCTS } = CATALOG_NAMESPACE;

export const rebuildProducts = async ({
    $performanceRecorder,
    $getLocaleConfigByKey,
    products,
    categories,
    locale,
}) => {
    $performanceRecorder.start(REBUILD_PRODUCTS);

    const productData =
        products.map(product => {
            const normalizedProduct = {
                ...product,
            };

            return Product.extendProductWithCategoriesData(
                normalizedProduct,
                categories,
                locale,
                $getLocaleConfigByKey
            );
        }) || [];

    $performanceRecorder.end(REBUILD_PRODUCTS);

    return productData.filter(product => !!product);
};
