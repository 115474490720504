<template>
    <FiltersModal
        v-if="isFiltersModalOpen"
        :is-opened="isFiltersModalOpen"
        :is-pending="isPending"
        :is-mobile="isMobile"
        :selected-filters-count="selectedFiltersCount"
        :initial-filter="initialFilter"
        @close="closeFiltersModal()"
        @apply-filters="applySearchConfig()"
        @reload-filters="atReloadFilters()"
    />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { applySearchConfiguration } from '@assets/filters';

import { FILTERS_MODAL } from '@search/configs/modals';

const { mapActions: mapSearchCategoryActions } = createNamespacedHelpers(
    'category'
);

export default {
    name: 'Filters',

    components: {
        FiltersModal: () => ({
            component: import(
                /* webpackChunkName: "filters-modal" */
                '@organisms/FiltersModal/FiltersModal'
            ),
        }),
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        isPending: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        selectedFiltersCount() {
            return this.$filterManager.selectedFiltersCount || 0;
        },

        initialFilter() {
            return this.$modals.getConfig(FILTERS_MODAL).filter || null;
        },

        isFiltersModalOpen() {
            return this.$modals.isOpen(FILTERS_MODAL);
        },
    },

    methods: {
        ...mapSearchCategoryActions(['reloadFilters']),

        closeFiltersModal() {
            this.$modals.close(FILTERS_MODAL);
        },

        applySearchConfig() {
            this.closeFiltersModal();

            applySearchConfiguration(this, this.isMobile);
        },

        async atReloadFilters() {
            await this.reloadFilters();
        },
    },
};
</script>
