const SPONSORED_IN_ROW_CLASS = 'sponsored-in-row';
const SPONSORED_IN_ROW_CLASS_ON_THREE_COLUMNS = 'sponsored-in-row-3c';
const SPONSORED_IN_ROW_CLASS_ON_TWO_COLUMNS = 'sponsored-in-row-2c';

export const sponsoredInRowClassesMap = new Map([
    [
        1,
        [
            SPONSORED_IN_ROW_CLASS,
            SPONSORED_IN_ROW_CLASS_ON_TWO_COLUMNS,
            SPONSORED_IN_ROW_CLASS_ON_THREE_COLUMNS,
        ],
    ],
    [2, [SPONSORED_IN_ROW_CLASS, SPONSORED_IN_ROW_CLASS_ON_THREE_COLUMNS]],
    [3, [SPONSORED_IN_ROW_CLASS]],
    [8, [SPONSORED_IN_ROW_CLASS]],
    [9, [SPONSORED_IN_ROW_CLASS, SPONSORED_IN_ROW_CLASS_ON_THREE_COLUMNS]],
    [
        11,
        [
            SPONSORED_IN_ROW_CLASS,
            SPONSORED_IN_ROW_CLASS_ON_TWO_COLUMNS,
            SPONSORED_IN_ROW_CLASS_ON_THREE_COLUMNS,
        ],
    ],
]);
