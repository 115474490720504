import { ASYNC_CMS_BLOCKS_SERVICE_CONFIG } from '@async-services/cmsBlocks/meta';

import {
    BANNER_TYPE_CONTENT_STATIC_BLOCK,
    BANNER_PB_TYPE_CONTENT_STATIC_BLOCK,
    TEXT_TYPE_CONTENT_STATIC_BLOCK,
} from '@configs/category';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

export const prepareStaticContentData = async ({
    $asyncServices,
    $errorHandler,
    route,
}) => {
    try {
        const { path } = route;
        const blocks = await $asyncServices.use(
            ASYNC_CMS_BLOCKS_SERVICE_CONFIG.NAME,
            ASYNC_CMS_BLOCKS_SERVICE_CONFIG.METHODS.GET_STATIC_CONTENT,
            { categoryPath: path }
        );

        if (!blocks.length) {
            return { bannerHTML: '', seoDescription: '', crossBannerHTML: '' };
        }

        const contentBlocksMap = blocks.reduce((mapObj, { type, content }) => {
            mapObj[type] = mapObj[type]
                ? [...mapObj[type], content]
                : [content];

            return mapObj;
        }, {});

        return {
            bannerHTML:
                contentBlocksMap[BANNER_TYPE_CONTENT_STATIC_BLOCK]?.[0] || '',
            seoDescription:
                contentBlocksMap[TEXT_TYPE_CONTENT_STATIC_BLOCK]?.[0] || '',
            bannerPB:
                contentBlocksMap[BANNER_PB_TYPE_CONTENT_STATIC_BLOCK] || '',
        };
    } catch (err) {
        $errorHandler.captureError(err, {
            [ERROR_ACTION_TAG_NAME]: 'cmsBlocks.getStaticContent',
        });

        return null;
    }
};
