import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

import { NOT_FOUND_HTTP_CODE } from '@configs/http-codes';

import CategoryNotFoundException from '@exceptions/services/category/CategoryNotFoundException';

import {
    USE_SEARCH_SORTING_VARIANTS,
    SEARCH_SERVICE_CATEGORY_LISTINGS,
} from '@search/assets/search-service';

import { prepareProductsCatalogData } from '@search/utils/prepare';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareCatalogDataFromCorrectEngine = async ({
    $asyncServices,
    $errorHandler,
    $abTests,
    route,
    redirect,
    searchRequestParams,
    urlFilters,
    locale,
}) => {
    const listingVariantAbcTest = $abTests.getVariant(
        USE_SEARCH_SORTING_VARIANTS
    );

    const isSelectedLocale = locale === 'pl_PL';

    const isSearchServiceCategoryPage = !!searchRequestParams?.categories.filter(
        category =>
            !!SEARCH_SERVICE_CATEGORY_LISTINGS.some(substring =>
                new RegExp(`(?:^|\\/)${substring}(?:\\/|$)`).test(category)
            )
    ).length;

    const outletListing = listingVariantAbcTest && !!urlFilters?.outlet;
    const newProductsListing =
        listingVariantAbcTest && !!(urlFilters.oferta?.[0] === 'nowosc');

    const searchServiceListings =
        listingVariantAbcTest &&
        isSearchServiceCategoryPage &&
        isSelectedLocale;

    const searchServiceAdditionalListings = outletListing || newProductsListing;

    if (searchServiceListings || searchServiceAdditionalListings) {
        if (
            listingVariantAbcTest === 'hybrid50' ||
            listingVariantAbcTest === 'hybrid_score'
        ) {
            const sortingVariant = { sortingVariant: listingVariantAbcTest };
            const requestParams = { ...sortingVariant, ...searchRequestParams };

            const {
                data,
                error,
                status,
                originalResponse,
            } = await $asyncServices.use(
                CATALOG_SERVICE_NAME,
                CATALOG_SERVICE_METHODS.GET_SEARCH_SERVICE_PRODUCTS,
                requestParams
            );

            if (!data) {
                $errorHandler.captureError(
                    new Error(error?.message || 'UNKNOWN_ERROR', {
                        cause: error,
                    }),
                    {
                        [ERROR_ACTION_TAG_NAME]:
                            'getCatalogSearchServicegListingData',
                    },
                    {
                        searchRequestParams,
                        data,
                        error,
                        status,
                        originalResponse,
                    }
                );

                return prepareProductsCatalogData({
                    $asyncServices,
                    $errorHandler,
                    route,
                    redirect,
                    searchRequestParams,
                });
            }

            if (!error && data) {
                const {
                    products: catalogProducts = [],
                    search_query: searchQuery = {},
                    sort_options: sortOptions = [],
                    total = null,
                    totalFormatted = null,
                } = data;

                return {
                    catalogProducts,
                    searchQuery,
                    sortOptions,
                    total,
                    totalFormatted,
                };
            }

            if (error) {
                const errorToThrow = new Error(
                    error?.message || 'UNKNOWN_ERROR',
                    {
                        cause: error,
                    }
                );

                $errorHandler.captureError(
                    errorToThrow,
                    {
                        [ERROR_ACTION_TAG_NAME]:
                            'getCatalogSearchServicegListingData',
                    },
                    {
                        requestParams: searchRequestParams,
                        data,
                        error,
                        status,
                        originalResponse,
                    }
                );

                if (status === NOT_FOUND_HTTP_CODE) {
                    throw new CategoryNotFoundException(
                        JSON.stringify(searchRequestParams)
                    );
                }

                throw errorToThrow;
            }
        }

        return prepareProductsCatalogData({
            $asyncServices,
            $errorHandler,
            route,
            redirect,
            searchRequestParams,
        });
    }

    return prepareProductsCatalogData({
        $asyncServices,
        $errorHandler,
        route,
        redirect,
        searchRequestParams,
    });
};
