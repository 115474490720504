<template>
    <WithScroll
        :is-horizontal="true"
        :autoscroll-after-change-to-left="true"
        :is-enabled="isMobile"
        class="category-navigation-tree"
    >
        <div v-if="backLink" class="back-button-wrapper">
            <ButtonComponent
                :has-icon="true"
                :variant="BUTTON_VARIANT"
                :to="backLink.url"
                class="back-link"
                @click="sendAnalyticsGoBackEvent(backLink.label)"
            >
                <template #icon>
                    <Icon :icon="ShevronLeft" width="20px" height="20px" />
                </template>
                {{ backLink.label }}
            </ButtonComponent>
        </div>

        <ul class="tree" @click.prevent="goToCategory($event)">
            <CategoryNavigationTreeItem
                v-for="(category, index) in flatCategoryTree"
                :key="`category-navigation-tree-${category.url}-${index}`"
                :category="category"
                :locale="locale"
                :active-category-url="activeCategoryUrl"
                :is-search-page="isSearchPage"
                :url-filters="urlFilters"
            />
        </ul>
    </WithScroll>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { QUERY_SEARCH } from '@configs/query-keys/search';

import { mapCategoryUrlToBreadcrumbsForAnalytics } from '@assets/path';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';
import {
    CATEGORY_SIDE_MENU_GO_BACK,
    CATEGORY_SIDE_MENU_SELECTION,
    CATEGORY_SIDE_MENU_OPEN_CONTEXTMENU,
} from '@analytics-modules/catalog/types/Events';

import WithScroll from '@atoms/WithScroll/WithScroll';

// eslint-disable-next-line max-len

import { ShevronLeft } from '@eobuwie-ui/icons/v1/other';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';
import CategoryNavigationTreeItem from './CategoryNavigationTreeItem';

const {
    mapState: mapCategoryState,
    mapActions: mapCategoryActions,
    mapGetters: mapCategoryGetters,
} = createNamespacedHelpers('category');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapFiltersState } = createNamespacedHelpers('filters');

export default {
    name: 'CategoryNavigationTree',

    components: {
        WithScroll,
        ButtonComponent,
        Icon,
        CategoryNavigationTreeItem,
    },

    props: {
        isSearchPage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapConfigGetters(['locale']),
        ...mapCategoryGetters(['flatCategoryTree']),
        ...mapCategoryState(['navigation']),
        ...mapFiltersState(['urlFilters']),

        activeCategoryUrl() {
            const {
                locale,
                navigation: { categories: [category] = [] },
            } = this;

            if (category) {
                return category.translations?.[locale]?.url;
            }

            return '';
        },

        backLink() {
            const {
                locale,
                navigation: { bread_crumbs: { items: breadcrumbs = [] } = {} },
            } = this;

            let backLinkObject;

            if (breadcrumbs.length > 1) {
                const backLink =
                    breadcrumbs[breadcrumbs.length - 2].translations[locale];

                const { query } = this.$route;

                let url = this.$createCategoryPath(backLink.url);

                if (this.isSearchPage) {
                    url = this.$createSearchPath(
                        query[QUERY_SEARCH],
                        backLink.url
                    );
                }

                backLinkObject = {
                    ...backLink,
                    url,
                };
            }

            return backLinkObject;
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY;
        this.ShevronLeft = ShevronLeft;
    },

    methods: {
        ...mapCategoryActions(['setUrl']),

        goToCategory(event) {
            const attribute = 'href';

            let eventTarget = event.target;

            while (eventTarget && !eventTarget.hasAttribute(attribute)) {
                eventTarget = eventTarget.parentElement;
            }

            if (!eventTarget) {
                return;
            }

            const url = eventTarget.getAttribute(attribute);

            if (!url) {
                this.$errorHandler.captureError(
                    new Error(`Invalid attribute ${attribute}`),
                    {
                        [ERROR_ACTION_TAG_NAME]: 'goToCategory',
                    }
                );

                return;
            }

            this.emitAnalytics(url, event.type === 'contextmenu');

            this.$router.push(url);
        },

        sendAnalyticsGoBackEvent(label) {
            const {
                locale,
                navigation: { bread_crumbs: { items = [] } = {} },
            } = this;

            const oldBreadcrumbs = items.map(
                ({
                    translations: {
                        [locale]: { label: breadcrumbName },
                    },
                }) => breadcrumbName
            );

            const nameIndex = oldBreadcrumbs.indexOf(label);

            const newBreadcrumbs = oldBreadcrumbs.slice(0, nameIndex + 1);

            this.$analytics.moduleEmit(
                CATALOG_MODULE_NAME,
                CATEGORY_SIDE_MENU_GO_BACK,
                {
                    newBreadcrumbs,
                    oldBreadcrumbs,
                    page: this.$route.name,
                }
            );
        },

        emitAnalytics(url, contextmenu = false) {
            const eventType = contextmenu
                ? CATEGORY_SIDE_MENU_OPEN_CONTEXTMENU
                : CATEGORY_SIDE_MENU_SELECTION;

            let eventData;

            if (contextmenu) {
                eventData = { url };
            } else {
                eventData = {
                    breadcrumbs: mapCategoryUrlToBreadcrumbsForAnalytics(
                        decodeURIComponent(url)
                    ),

                    page: this.$route.name,
                };
            }

            this.$analytics.moduleEmit(
                CATALOG_MODULE_NAME,
                eventType,
                eventData
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.category-navigation-tree {
    @apply overflow-hidden;

    &.with-scroll:not(.desktop-only) {
        &::-webkit-scrollbar-thumb {
            @apply bg-transparent;
        }

        &::-webkit-scrollbar-track {
            @apply bg-transparent;
        }
    }

    .tree {
        @apply flex flex-row items-start;
    }

    .back-button-wrapper {
        @apply hidden;
    }

    @screen mobile-only {
        @apply -mx-ui-4 pl-ui-4;

        .tree {
            @apply pr-ui-4 gap-ui-1;
            width: max-content;
        }
    }

    @screen lg {
        @apply py-ui-3;

        .tree {
            @apply block;
        }

        .back-button-wrapper {
            @apply block w-full mb-ui-4;

            :deep(.button-component) {
                @apply justify-start w-full;
            }
        }
    }
}
</style>
