export const getSearchQueryComplexity = phrase => {
    const allWhitespacesRegexp = /([\s]+)/g;
    const removeSpecialCharactersRegexp = /[^a-zA-Z0-9 ]/g;
    const normalizedPhrase =
        phrase.replace(removeSpecialCharactersRegexp, '').trim() || '';
    const whitespaceCount =
        normalizedPhrase.match(allWhitespacesRegexp)?.length || 0;

    return whitespaceCount + 1;
};

export const getSearchDataResults = ({
    route = { query: {} },
    searchResults = null,
}) => {
    const searchQuery = route.query.q || null;

    return {
        searchQuery,
        searchQueryComplexity: searchQuery
            ? getSearchQueryComplexity(searchQuery)
            : null,
        searchResults,
    };
};
