<script>
import { createNamespacedHelpers } from 'vuex';

import {
    PRODUCT_CARD_SELECTOR,
    PRODUCTS_LIMIT_PER_IMPRESSION,
} from '@analytics-types/Analytics';
import { IMPRESSION_VIEW } from '@analytics-types/Events';

import DataLayerProducts from '@models/Analytics/DataLayerProducts';

import { chunkBySize } from '@assets/array';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'WithProductImpressionViewEvent',

    props: {
        products: {
            type: Array,
            required: true,
        },

        isPageEventEmittedDeferred: {
            type: Object,
            required: true,
        },

        productsPerPage: {
            type: Number,
            default: 0,
        },

        currentPage: {
            type: Number,
            default: 0,
        },

        viewPlacementEventUrls: {
            type: Array,
            default: () => [],
        },

        attributionToken: {
            type: [String, null],
            default: null,
        },

        searchEngineOffset: {
            type: [Number, null],
            default: null,
        },
    },

    data() {
        return {
            observer: null,
            lastSendProductIndex: 0,
            lastViewedProductIndex: 0,
            isEmittedViewPlacementEvent: false,
        };
    },

    computed: {
        ...mapConfigGetters(['currency', 'locale']),

        options() {
            return {
                root: null,
                threshold: [0],
                rootMargin: '0px 0px 0px 0px',
            };
        },
    },

    watch: {
        products: {
            async handler() {
                this.disconnectObserver();
                await this.$nextTick();
                this.initObserver();
            },
        },
    },

    async mounted() {
        this.initObserver();

        await this.isPageEventEmittedDeferred.promise;
        this.emitProductImpressionViewEvent();
    },

    beforeDestroy() {
        this.disconnectObserver();
    },

    methods: {
        disconnectObserver() {
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
        },

        initObserver() {
            this.lastViewedProductIndex = 0;
            this.lastSendProductIndex = 0;
            this.isEmittedViewPlacementEvent = false;

            const productCards = document.querySelectorAll(
                `[${PRODUCT_CARD_SELECTOR}]`
            );
            const productCardsCount = productCards.length;

            this.observer = new IntersectionObserver(async entries => {
                if (!entries.length) {
                    return;
                }

                const entriesIntersecting = entries.filter(
                    ({ isIntersecting }) => isIntersecting
                );

                if (!entriesIntersecting.length) {
                    return;
                }

                const { target } = entriesIntersecting.pop();
                const index = parseInt(
                    target.getAttribute(PRODUCT_CARD_SELECTOR),
                    10
                );

                if (index < this.lastViewedProductIndex) {
                    return;
                }

                this.lastViewedProductIndex = index;

                this.isPageEventEmittedDeferred.promise.then(() => {
                    this.emitProductImpressionViewEvent();
                });

                if (index + 1 === productCardsCount) {
                    this.disconnectObserver();
                }
            }, this.options);

            productCards.forEach(productCard => {
                this.observer.observe(productCard);
            });
        },

        emitProductImpressionViewEvent() {
            const {
                currency,
                lastSendProductIndex,
                lastViewedProductIndex,
                currentPage,
                productsPerPage,
                $route: { path: routePath },
                attributionToken,
                searchEngineOffset,
            } = this;

            if (
                lastSendProductIndex < lastViewedProductIndex ||
                (lastSendProductIndex === 0 && lastViewedProductIndex === 0)
            ) {
                const productsChunks = chunkBySize(
                    this.products.slice(
                        lastSendProductIndex,
                        lastViewedProductIndex + 1
                    ),
                    PRODUCTS_LIMIT_PER_IMPRESSION
                );

                const positionOffset = (currentPage - 1) * productsPerPage + 1;

                productsChunks.forEach((chunk, chunkNumber) => {
                    const products = chunk.map((product, productIndex) => {
                        if (product.isSponsored) {
                            if (!this.isEmittedViewPlacementEvent) {
                                this.viewPlacementEventUrls.forEach(beacon =>
                                    this.$services.adTech.notifyBeacon({
                                        beacon,
                                    })
                                );

                                this.isEmittedViewPlacementEvent = true;
                            }

                            product.adTechEvents.onViewBeacons.forEach(beacon =>
                                this.$services.adTech.notifyBeacon({ beacon })
                            );
                        }

                        return {
                            ...product,
                            position:
                                positionOffset +
                                lastSendProductIndex +
                                productIndex +
                                chunkNumber * PRODUCTS_LIMIT_PER_IMPRESSION,
                        };
                    });

                    this.$analytics.emit(IMPRESSION_VIEW, {
                        currency,
                        products: new DataLayerProducts({
                            products,
                            routePath,
                        }).build(),
                        attributionToken,
                        searchEngineOffset,
                        route: this.$route,
                    });
                });
            }

            this.lastSendProductIndex = lastViewedProductIndex + 1;
        },
    },

    render() {
        return this.$slots.default;
    },
};
</script>
