import { filtersToParamArray } from '@assets/path';

export const getSearchData = (selectedCodes = {}, codes = [], sort = {}) => {
    const { orderCode, orderDir } = sort;

    return {
        filters: Object.fromEntries(
            codes.map(code => {
                const value = selectedCodes[code];
                let joinedString = null;

                if (value) {
                    joinedString = value.join(',');
                }

                return [`filter_${code}`, joinedString];
            })
        ),
        order: orderCode ? `${orderCode}_${orderDir}` : null,
        availableFilters: codes.join(),
        selectedFilters: filtersToParamArray(selectedCodes).join('|'),
    };
};
