<script>
import { QUERY_CURRENT_PAGE } from '@configs/query-keys/pagination';

import { QUERY_ORDER, QUERY_ORDER_DIR } from '@configs/query-keys/sort';

import {
    QUERY_SEARCH,
    QUERY_SEARCH_CATEGORY,
} from '@configs/query-keys/search';

export default {
    name: 'RouterPagination',
    methods: {
        navigateToPage(pageNumber) {
            const { path, query } = this.$route;

            this.$router.push({
                path,
                query: {
                    ...query,
                    [QUERY_CURRENT_PAGE]: pageNumber,
                },
            });
        },

        createPaginationPath(pageNumber, rangeSeparator = '...') {
            const {
                name,
                params: { url },
                query: {
                    [QUERY_ORDER]: order,
                    [QUERY_ORDER_DIR]: orderDir,
                    [QUERY_SEARCH]: search,
                    [QUERY_SEARCH_CATEGORY]: category,
                },
            } = this.$route;

            if (pageNumber === rangeSeparator) {
                return null;
            }

            let newQuery = {
                [QUERY_SEARCH]: search,
                [QUERY_SEARCH_CATEGORY]: category,
                [QUERY_ORDER]: order,
                [QUERY_ORDER_DIR]: orderDir,
                [QUERY_CURRENT_PAGE]: pageNumber,
            };

            if (pageNumber === 1) {
                newQuery = {
                    [QUERY_SEARCH]: search,
                    [QUERY_SEARCH_CATEGORY]: category,
                    [QUERY_ORDER]: order,
                    [QUERY_ORDER_DIR]: orderDir,
                };
            }

            const {
                route: { fullPath },
            } = this.$router.resolve({
                name,
                params: {
                    url,
                },
                query: newQuery,
            });

            return fullPath.replace(/%2F/g, '/');
        },
    },

    render() {
        return this.$scopedSlots.default({
            navigateToPage: this.navigateToPage,
            createPaginationPath: this.createPaginationPath,
        });
    },
};
</script>
