import { getUrlForSeomatic, makeSeomaticRedirect } from '@assets/seomatic';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const {
    NAME: SEOMATIC_SERVICE_NAME,
    METHODS: SEOMATIC_SERVICE_METHODS,
} = ASYNC_SEOMATIC_SERVICE_CONFIG;

export const prepareSeomaticData = async ({
    $asyncServices,
    $errorHandler,
    route,
    redirect,
    req,
}) => {
    const urlForSeomatic = getUrlForSeomatic(req, route);

    try {
        const data = await $asyncServices.use(
            SEOMATIC_SERVICE_NAME,
            SEOMATIC_SERVICE_METHODS.GET_SEO,
            { url: urlForSeomatic }
        );

        if (data?.redirect) {
            return makeSeomaticRedirect(data.redirect, redirect, route);
        }

        const {
            name: seomaticName = '',
            description: seomaticSubHeading = '',
            meta: seomaticMeta = {},
            canonical_url: seomaticCanonicalUrl = '',
        } = data?.seo || {};

        return {
            seomaticName,
            seomaticSubHeading,
            seomaticMeta,
            seomaticCanonicalUrl,
        };
    } catch (err) {
        $errorHandler.captureError(
            err,
            { [ERROR_ACTION_TAG_NAME]: 'loadSeomaticData' },
            { urlForSeomatic }
        );

        return null;
    }
};
