import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareSponsoredData = async ({
    $asyncServices,
    $errorHandler,
    $ua,
    searchRequestParams,
}) => {
    if (process.server && $ua?.isBot) {
        return null;
    }

    try {
        const sponsoredData = await $asyncServices.use(
            CATALOG_SERVICE_NAME,
            CATALOG_SERVICE_METHODS.GET_SPONSORED,
            searchRequestParams
        );

        const {
            sponsoredProducts = [],
            sponsorshipDetails: {
                load_event_urls: loadPlacementEventUrls = [],
                view_event_urls: viewPlacementEventUrls = [],
            } = {},
        } = sponsoredData;

        return {
            sponsoredProducts,
            loadPlacementEventUrls,
            viewPlacementEventUrls,
        };
    } catch (err) {
        $errorHandler.captureError(
            err,
            { [ERROR_ACTION_TAG_NAME]: 'getSponsoredData' },
            searchRequestParams
        );

        return null;
    }
};
