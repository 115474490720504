<script>
import { createNamespacedHelpers } from 'vuex';

import { IMPRESSION_CLICK } from '@analytics-types/Events';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { mapProductToDataLayerProduct } from '@assets/recommendations';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'WithProductImpressionClickEvent',

    props: {
        productsPerPage: {
            type: Number,
            default: 0,
        },

        currentPage: {
            type: Number,
            default: 0,
        },

        actionField: {
            type: String,
            default: '',
        },

        searchDetails: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        ...mapConfigGetters(['currency', 'locale']),
    },

    methods: {
        emitProductAdTechEvent({ product, clickLabel }) {
            if (product.isSponsored) {
                product.adTechEvents.onClickBeacons.forEach(beacon =>
                    this.$services.adTech.notifyBeacon({ beacon, clickLabel })
                );
            }
        },

        emitProductImpressionClickEvent({
            product,
            position: positionOnListing,
            clickLabel,
        }) {
            const {
                currentPage,
                productsPerPage,
                currency,
                $route: { path },
                searchDetails,
            } = this;

            this.emitProductAdTechEvent({
                product,
                clickLabel,
            });

            let position = positionOnListing;

            if (currentPage && productsPerPage) {
                position =
                    (currentPage - 1) * productsPerPage + positionOnListing;
            }

            const productReviews = {
                totalCount: '',
            };

            const analyticsProduct = new DataLayerProduct({
                product,
                reviews: productReviews,
                position,
            }).build();

            const newAnalyticsProduct = mapProductToDataLayerProduct({
                product,
                position,
            });

            if (!analyticsProduct && !newAnalyticsProduct) {
                return;
            }

            this.$analytics.emit(IMPRESSION_CLICK, {
                currency,
                searchDetails,
                product: analyticsProduct || newAnalyticsProduct,
                actionFieldList: this.actionField || path,
                route: this.$route,
            });
        },
    },

    render() {
        return this.$scopedSlots.default({
            emitEvent: this.emitProductImpressionClickEvent,
            emitProductAdTechEvent: this.emitProductAdTechEvent,
        });
    },
};
</script>
