import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ASYNC_CATALOG_SERVICE_CONFIG } from '@search/async-services/catalog/meta';

import { sortCategoriesTreeAlphabetically } from '@search/utils';

const {
    NAME: CATALOG_SERVICE_NAME,
    METHODS: CATALOG_SERVICE_METHODS,
} = ASYNC_CATALOG_SERVICE_CONFIG;

export const prepareNavigationData = async ({
    $asyncServices,
    $errorHandler,
    searchRequestParams,
}) => {
    const { locale } = searchRequestParams;

    const { data, error, status, originalResponse } = await $asyncServices.use(
        CATALOG_SERVICE_NAME,
        CATALOG_SERVICE_METHODS.GET_NAVIGATION,
        searchRequestParams
    );

    if (!error && data) {
        sortCategoriesTreeAlphabetically(data, locale);

        const {
            categories: [searchCategory = null] = [],
            bread_crumbs: { items: breadcrumbs = [] } = {},
        } = data;

        return { navigation: data, category: searchCategory, breadcrumbs };
    }

    const errorToThrow = new Error(error?.message || 'UNKNOWN_ERROR', {
        cause: error,
    });

    $errorHandler.captureError(
        errorToThrow,
        {
            [ERROR_ACTION_TAG_NAME]: 'getCatalogNavigationData',
        },
        {
            requestParams: searchRequestParams,
            data,
            error,
            status,
            originalResponse,
        }
    );

    return { navigation: {}, category: null, breadcrumbs: [] };
};
