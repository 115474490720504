<template>
    <div>
        <slot />
    </div>
</template>

<script>
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';

const SCROLL_DELAY = 50;
const RESIZE_DEBOUNCE = 100;

export default {
    name: 'StickyBlockBounded',

    props: {
        relativeElementSelector: {
            type: String,
            required: true,
        },

        offset: {
            type: Object,
            default() {
                return {
                    top: 10,
                    bottom: 10,
                };
            },

            validator(offset) {
                if (typeof offset !== 'object') {
                    return false;
                }

                const keys = Object.keys(offset);

                return keys.includes('top') && keys.includes('bottom');
            },
        },

        enabled: {
            type: Boolean,
            default: false,
        },

        scrollSticky: {
            type: Boolean,
            default: false,
        },

        scrollContainerSelector: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            frameId: null,
            stickyHeight: null,
            stickyWidth: null,
            stickyRect: null,
            stickyInitialTop: null,
            relativeElmOffsetTop: null,
            topPadding: null,
            lastState: null,
            currentState: null,
            currentScrollSticky: null,
            topOfScreen: null,
            lastDistanceFromTop: null,
            scrollingUp: null,
            scrollingDown: null,
            handleScrollDelayedFn: null,
            onResizeHandler: null,
        };
    },

    computed: {
        relativeElement() {
            return document.querySelector(this.relativeElementSelector);
        },

        scrollContainer() {
            if (this.scrollContainerSelector) {
                return document.querySelector(this.scrollContainerSelector);
            }

            return window;
        },

        stickyTopPos() {
            return (
                this.stickyRect.top +
                this.topOfScreen -
                this.offset.top -
                this.topPadding
            );
        },

        stickyBottomPos() {
            return (
                this.stickyRect.bottom + this.topOfScreen + this.offset.bottom
            );
        },

        bottomOfScreen() {
            return this.topOfScreen + this.scrollContainer.innerHeight;
        },

        relativeElmTopPos() {
            return (
                this.topOfScreen +
                this.relativeElement.getBoundingClientRect().top
            );
        },

        relativeElmBottomPos() {
            return (
                this.topOfScreen +
                this.relativeElement.getBoundingClientRect().bottom
            );
        },

        screenIsPastSticky() {
            return this.bottomOfScreen >= this.stickyBottomPos;
        },

        screenIsBeforeSticky() {
            return this.topOfScreen <= this.stickyTopPos;
        },

        screenIsBeforeRelativeElm() {
            return this.topOfScreen <= this.relativeElmTopPos - this.offset.top;
        },

        screenIsPastRelativeElm() {
            return (
                this.bottomOfScreen >=
                this.relativeElmBottomPos + this.offset.bottom
            );
        },

        screenIsInsideRelativeElm() {
            return (
                !this.screenIsBeforeRelativeElm && !this.screenIsPastRelativeElm
            );
        },
    },

    watch: {
        offset(val, oldVal) {
            if (val.top !== oldVal.top || val.bottom !== oldVal.bottom) {
                this.onScroll();
            }
        },
    },

    beforeMount() {
        this.handleScrollDelayedFn = throttle(this.handleScroll, SCROLL_DELAY);
        this.onResizeHandler = debounce(
            this.resizeHandler.bind(this),
            RESIZE_DEBOUNCE
        );
    },

    mounted() {
        if (typeof ResizeObserver !== 'undefined') {
            this.resizeObserver = new ResizeObserver(() => {
                this.handleScrollDelayedFn();
            });

            this.resizeObserver.observe(this.$el);
        }

        this.setHandleResize();
        this.stickyWidth = this.$el.offsetWidth;
        this.$el.classList.add('sticky-block');

        this.stickyInitialTop = this.getOffsetTop(this.$el);
        this.topPadding =
            this.stickyInitialTop - this.getOffsetTop(this.relativeElement);

        this.updateData();

        if (this.scrollSticky) {
            const stickyTotalHeight =
                this.stickyHeight + this.offset.bottom + this.offset.top;
            const shouldUseScrollSticky =
                this.scrollSticky &&
                stickyTotalHeight > this.scrollContainer.innerHeight;

            if (shouldUseScrollSticky) {
                this.initScrollSticky();
            }
        }

        this.handleScroll();
        this.setHandleScroll();
    },

    beforeDestroy() {
        this.handleScrollDelayedFn.cancel();
        this.onResizeHandler.cancel();
        this.removeHandleScroll();
        this.removeHandleResize();
        this.resizeObserver?.disconnect();
    },

    methods: {
        resizeHandler() {
            this.setScrollStickyTop();
            this.$el.style.width = null;
            this.updateData();
            this.stickyWidth = this.$el.offsetWidth;
        },

        setHandleResize() {
            window.addEventListener('resize', this.onResizeHandler);
        },

        removeHandleResize() {
            window.removeEventListener('resize', this.onResizeHandler);
        },

        setHandleScroll() {
            this.scrollContainer.addEventListener(
                'scroll',
                this.handleScrollDelayedFn
            );
        },

        removeHandleScroll() {
            this.scrollContainer.removeEventListener(
                'scroll',
                this.handleScrollDelayedFn
            );
        },

        updateData() {
            this.topOfScreen =
                this.scrollContainer.scrollTop || window.pageYOffset;
            this.stickyRect = this.$el.getBoundingClientRect();
            this.stickyHeight = this.$el.offsetHeight;
            this.relativeElmOffsetTop = this.getOffsetTop(this.relativeElement);
        },

        handleScroll() {
            if (this.frameId) {
                return;
            }

            this.frameId = window.requestAnimationFrame(() => {
                this.onScroll();
                this.frameId = null;
            });
        },

        onScroll() {
            if (!this.enabled || !this.relativeElement) {
                this.removeClasses();

                return;
            }

            this.updateData();

            const stickyIsBiggerThanRelativeElement =
                this.stickyHeight + this.offset.top >=
                this.relativeElement.offsetHeight;

            if (stickyIsBiggerThanRelativeElement) {
                if (
                    this.scrollSticky &&
                    this.currentScrollSticky !== 'scrollsticky-top'
                ) {
                    this.setScrollStickyTop();
                } else if (this.currentState !== 'sticky-top') {
                    this.setStickyTop();
                }

                return;
            }

            const stickyTotalHeight =
                this.stickyHeight + this.offset.bottom + this.offset.top;
            const shouldUseScrollSticky =
                this.scrollSticky &&
                stickyTotalHeight > this.scrollContainer.innerHeight;

            if (shouldUseScrollSticky) {
                this.handleScrollSticky();

                return;
            }

            this.handleSticky();
        },

        handleSticky() {
            if (
                this.topOfScreen <
                this.relativeElmOffsetTop - this.offset.top
            ) {
                this.setStickyTop();
            }

            if (
                this.topOfScreen >=
                    this.relativeElmOffsetTop - this.offset.top &&
                this.relativeElmBottomPos - this.offset.bottom >=
                    this.topOfScreen +
                        this.topPadding +
                        this.stickyHeight +
                        this.offset.top
            ) {
                this.setSticky();
            }

            if (
                this.relativeElmBottomPos - this.offset.bottom <
                this.topOfScreen +
                    this.topPadding +
                    this.stickyHeight +
                    this.offset.top
            ) {
                this.setStickyBottom();
            }

            this.lastState = this.currentState;
        },

        handleScrollSticky() {
            this.setScrollingDirection();

            if (this.screenIsBeforeRelativeElm) {
                this.setScrollStickyTop();
            } else if (this.screenIsPastRelativeElm) {
                this.setScrollStickyBottom();
            } else if (this.screenIsInsideRelativeElm) {
                const shouldSetStickyScrolling =
                    this.currentScrollSticky === 'scrollsticky-top' ||
                    this.currentScrollSticky === 'scrollsticky-bottom' ||
                    (this.currentScrollSticky === 'scrollsticky-up' &&
                        this.scrollingDown) ||
                    (this.currentScrollSticky === 'scrollsticky-down' &&
                        this.scrollingUp);

                if (this.screenIsBeforeSticky && this.scrollingUp) {
                    this.setScrollStickyUp();
                } else if (this.screenIsPastSticky && this.scrollingDown) {
                    this.setScrollStickyDown();
                } else if (shouldSetStickyScrolling) {
                    this.setScrollStickyScrolling();
                }
            }

            this.lastScrollStickyState = this.currentScrollSticky;
            this.lastDistanceFromTop = this.topOfScreen;
        },

        initScrollSticky() {
            if (this.bottomOfScreen < this.stickyBottomPos) {
                this.setScrollStickyTop();
            } else if (this.screenIsInsideRelativeElm) {
                this.setScrollStickyDown();
            } else if (this.screenIsPastRelativeElm) {
                this.setScrollStickyBottom();
            } else {
                this.setScrollStickyScrolling();
            }
        },

        addWidthEl() {
            if (this.enabled) {
                this.$el.style.width = `${this.stickyWidth}px`;
            }
        },

        setScrollStickyScrolling() {
            this.currentScrollSticky = 'scrollsticky-scrolling';
            this.$el.style.top = `${
                Math.floor(this.stickyRect.top) +
                this.topOfScreen -
                this.stickyInitialTop
            }px`;
            this.$el.style.bottom = 'auto';
            this.removeClasses();
        },

        setScrollStickyUp() {
            this.currentScrollSticky = 'scrollsticky-up';

            if (this.currentScrollSticky !== this.lastScrollStickyState) {
                this.$el.style.top = `${this.topPadding + this.offset.top}px`;
                this.$el.style.bottom = 'auto';
                this.addWidthEl();
                this.removeClasses();
                this.$el.classList.add('sticky');
            }
        },

        setScrollStickyDown() {
            this.currentScrollSticky = 'scrollsticky-down';

            if (this.currentScrollSticky !== this.lastScrollStickyState) {
                this.$el.style.bottom = `${this.offset.bottom}px`;
                this.$el.style.top = 'auto';
                this.addWidthEl();
                this.removeClasses();
                this.$el.classList.add('sticky');
            }
        },

        setScrollStickyTop() {
            this.currentScrollSticky = 'scrollsticky-top';
            this.$el.style.top = 0;
            this.$el.style.bottom = 'auto';
            this.addWidthEl();
            this.removeClasses();
        },

        setScrollStickyBottom() {
            this.currentScrollSticky = 'scrollsticky-bottom';
            this.$el.style.top = `${
                this.relativeElmBottomPos -
                this.stickyInitialTop -
                this.stickyHeight
            }px`;
            this.$el.style.bottom = 'auto';
            this.addWidthEl();
            this.removeClasses();
        },

        setScrollingDirection() {
            if (this.topOfScreen > this.lastDistanceFromTop) {
                this.scrollingDown = true;
                this.scrollingUp = false;
            } else {
                this.scrollingUp = true;
                this.scrollingDown = false;
            }
        },

        setStickyTop() {
            this.currentState = 'sticky-top';
            this.addWidthEl();

            if (this.currentState !== this.lastState) {
                this.removeClasses();
                this.$el.classList.remove('sticky');
                this.$el.classList.add('sticky-top');
                this.$el.style.top = null;
            }
        },

        setSticky() {
            this.currentState = 'sticky';
            this.$el.style.top = `${this.topPadding + this.offset.top}px`;
            this.addWidthEl();

            if (this.currentState !== this.lastState) {
                this.removeClasses();
                this.$el.classList.add('sticky');
            }
        },

        setStickyBottom() {
            this.currentState = 'sticky-bottom';
            this.addWidthEl();

            this.$el.style.top = `${
                this.relativeElement.offsetHeight -
                this.stickyHeight -
                this.offset.bottom -
                this.topPadding
            }px`;

            if (this.currentState !== this.lastState) {
                this.removeClasses();
                this.$el.classList.add('sticky-bottom');
            }
        },

        removeClasses() {
            this.$el.classList.remove('sticky-top');
            this.$el.classList.remove('sticky');
            this.$el.classList.remove('sticky-bottom');
        },

        getOffsetTop(element) {
            let yPosition = 0;
            let nextElement = element;

            while (nextElement) {
                yPosition += nextElement.offsetTop;
                nextElement = nextElement.offsetParent;
            }

            return yPosition;
        },
    },
};
</script>

<style lang="scss" scoped>
.sticky-block {
    @apply relative;
}

.sticky {
    @apply fixed;
}

.sticky-bottom {
    @apply relative;
}
</style>
