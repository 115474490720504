<template>
    <div class="page-header">
        <ContainerContent>
            <h1 class="heading">
                <template v-if="hasSuggested">
                    {{ headerText }}
                </template>
                <template v-else>
                    {{ header }}
                    <span v-if="isSearchPage" class="query-text">
                        "{{ searchQuery }}"
                    </span>
                </template>
            </h1>
            <p v-if="subHeaderText" class="sub-heading">{{ subHeaderText }}</p>
        </ContainerContent>
    </div>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'PageHeader',

    components: {
        ContainerContent,
    },

    props: {
        headerText: {
            type: String,
            required: true,
        },

        isSearchPage: {
            type: Boolean,
            default: false,
        },

        searchQuery: {
            type: String,
            default: '',
        },

        subHeaderText: {
            type: String,
            default: '',
        },

        hasSuggested: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        header() {
            return this.isSearchPage
                ? this.$t('Searching for phrase:')
                : this.headerText;
        },
    },
};
</script>

<style lang="scss" scoped>
.page-header {
    .heading {
        @apply w-full text-ui-text-primary-default text-left;
        @apply font-ui-header-l;

        .query-text {
            @apply text-ui-text-primary-accent;
        }
    }

    .sub-heading {
        @apply font-ui-body-m text-ui-text-secondary-default mt-ui-1 text-left w-full;
    }

    @screen lg {
        .heading {
            @apply font-ui-header-xxl;
        }

        .sub-heading {
            @apply font-ui-body-l;
        }
    }
}
</style>
