<template>
    <ul
        :data-test-id="`PRODUCT_LIST-${listId}`"
        :class="['products-list', `list-${listId}`]"
    >
        <li
            v-for="product in products"
            :key="`item-${product.productIndex}`"
            class="product-item"
        >
            <ProductCardSmall
                :key="`products-list-item-${product.productIndex}`"
                class="product"
                :class="
                    checkSponsoredInRow(
                        product.productIndex,
                        product.isSponsored
                    )
                "
                :[PRODUCT_CARD_SELECTOR]="product.productIndex"
                :product="product.designSystem"
                :color-variants="colorVariants[product.sku]"
                :is-sponsored="product.isSponsored"
                :is-favourite="isOnWishlist(product.sku)"
                :is-picture-lazy="product.productIndex > 4"
                :picture-fetch-priority="getFetchPriority(product.productIndex)"
                :picture-sizes="listingProductPictureSizes"
                :is-link-enabled="false"
                :sponsored-label="$t('Sponsored')"
                :omnibus-minimal-label="$t('Lowest price')"
                :omnibus-regular-label="$t('Regular price')"
                :data-test-id="PRODUCT_LIST_ITEM"
                @toggle-favourite="toggleWishlist(product)"
                @click.exact.left.native="
                    $emit('product-click', {
                        product,
                        mouseEvent: $event,
                        position: product.productIndex + 1,
                    })
                "
                @click.ctrl.exact.native="
                    $emit('product-click', {
                        product,
                        mouseEvent: $event,
                        position: product.productIndex + 1,
                    })
                "
                @click.exact.native.middle="
                    $emit('product-click', {
                        product,
                        mouseEvent: {
                            ...$event,
                            middleKey: true,
                            position: product.productIndex + 1,
                        },
                    })
                "
                @contextmenu.native="
                    $emit('product-open-contextmenu', { product })
                "
            />
        </li>
    </ul>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { FETCH_PRIORITY } from '@types/FetchPriority';
import { BANNER_TYPES } from '@types/Banners';
import { PRODUCT_LIST_ITEM } from '@types/AutomaticTestIDs';

import { sponsoredInRowClassesMap } from '@search/configs/sponsored-in-row-on-product-list';

import { PRODUCT_CARD_SELECTOR } from '@analytics-types/Analytics';

import { ProductCardSmall } from '@eobuwie-ui/components/ProductCardSmall/v1';
import Omnibus from '@mixins/Omnibus';

const {
    mapActions: mapCustomerActions,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');

export default {
    name: 'ProductsList',

    components: {
        ProductCardSmall,
    },

    mixins: [Omnibus],

    props: {
        listId: {
            type: Number,
            default: 1,
        },

        products: {
            type: Array,
            required: true,
        },

        productsColorVariants: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCustomerGetters(['isOnWishlist']),

        numberOfSponsored() {
            return this.products.reduce(
                (acc, { isSponsored }) => (isSponsored ? acc + 1 : acc),
                0
            );
        },

        colorVariants() {
            return Object.fromEntries(
                this.products.map(product => {
                    const colorVariants =
                        this.productsColorVariants[
                            product.colorVariantsLink?.value
                        ]
                            ?.filter(variant => variant.sku !== product.sku)
                            .map(({ designSystem }) => designSystem) || [];

                    return [product.sku, colorVariants];
                })
            );
        },
    },

    beforeCreate() {
        this.BANNER_TYPE_ADTECH = BANNER_TYPES.ADTECH;
        this.PRODUCT_LIST_ITEM = PRODUCT_LIST_ITEM;
        this.PRODUCT_CARD_SELECTOR = PRODUCT_CARD_SELECTOR;
        this.listingProductPictureSizes =
            // eslint-disable-next-line max-len
            '(min-width: 1280px) 240px, (min-width: 868px) 308px, (min-width: 768px) 255px, calc(50vw - 32px)';
    },

    methods: {
        ...mapCustomerActions(['addToWishlist', 'removeFromWishlist']),

        getFetchPriority(index) {
            return index === 0 ? FETCH_PRIORITY.HIGH : null;
        },

        async toggleWishlist(product) {
            const { addToWishlist, removeFromWishlist, isOnWishlist } = this;

            if (isOnWishlist(product.sku)) {
                await removeFromWishlist(product);
            } else {
                await addToWishlist(product);
            }
        },

        checkSponsoredInRow(index, isSponsored) {
            if (index > 12 || isSponsored || this.numberOfSponsored === 0) {
                return;
            }

            if (this.numberOfSponsored <= 2 && index < 4) {
                return sponsoredInRowClassesMap.get(index);
            }

            if (this.numberOfSponsored > 2 && index < 12) {
                return sponsoredInRowClassesMap.get(index);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.products-list {
    @apply flex flex-wrap w-full;

    .product-item {
        @apply border-b-1 border-b-border;
    }

    .product {
        @apply mx-auto outline-none h-full;
    }

    @screen mobile-only {
        .product-item {
            @apply w-1/2;
        }

        .sponsored-in-row-2c {
            :deep(.product-card-link) {
                @apply pt-ui-7;
            }
        }

        .product-item:nth-of-type(2n + 1) {
            @apply border-r-1;
        }
    }

    @media (min-width: 768px) and (max-width: 1279px) {
        .product-item {
            @apply w-1/3;
        }

        .sponsored-in-row-3c {
            :deep(.product-card-link) {
                @apply pt-ui-7;
            }
        }

        .product-item:nth-of-type(3n + 1),
        .product-item:nth-of-type(3n + 2) {
            .product {
                @apply border-r-1;
            }
        }
    }

    @screen largeDesktop {
        .product-item {
            @apply w-1/4;
        }

        .sponsored-in-row {
            :deep(.product-card-link) {
                @apply pt-ui-7;
            }
        }

        .product-item:nth-of-type(4n + 1),
        .product-item:nth-of-type(4n + 2),
        .product-item:nth-of-type(4n + 3) {
            @apply border-r-1;
        }
    }
}
</style>
