<template>
    <div
        v-if="shouldShowWidget && isPromotionWidgetStaticBlock"
        class="promotion-widget"
    >
        <StaticHtmlLinkHandler class="promotion-widget-block">
            <StaticBlock
                :content="promotionWidgetStaticBlock.content"
                class="static-block"
            />
            <ButtonIcon
                :variant="BUTTON_ICON_VARIANT"
                class="close"
                @click.native="closePromotionWidget()"
            >
                <Icon :icon="Close" />
            </ButtonIcon>
        </StaticHtmlLinkHandler>
    </div>
</template>

<script>
import throttle from 'lodash.throttle';
import { createNamespacedHelpers } from 'vuex';

import {
    PSB_PROMOTION_WIDGET_MEN,
    PSB_PROMOTION_WIDGET_WOMEN,
    PROMOTION_WIDGET_BLOCK_IDS,
} from '@configs/promotions';
import { MENS } from '@configs/context';
import { COOKIES_IS_PROMOTION_WIDGET_ENABLED } from '@configs/storage';

import cookies from '@services/cookies';

import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';

import { Close } from '@eobuwie-ui/icons/v1/action';

import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

const { mapGetters: mapContextGetters } = createNamespacedHelpers('context');
const {
    mapGetters: mapCmsBlockGetters,
    mapActions: mapCmsBlockActions,
} = createNamespacedHelpers('cmsBlock');

const cookieKeyWithPrefix = cookies.createCookieWithPrefix(
    COOKIES_IS_PROMOTION_WIDGET_ENABLED
);

const SCROLL_DELAY = 50;
const PART_HEIGHT_TRIGGER_ACTION = 0.25;

export default {
    name: 'PromotionWidget',

    components: {
        ButtonIcon,
        Icon,
        StaticBlock,
        StaticHtmlLinkHandler,
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            handleScrollDelayedFn: null,
            isPositionTriggerHeight: false,
            isLoaded: false,
            isPromotionWidgetEnabled: false,
        };
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapContextGetters(['isMan']),

        promotionWidgetStaticBlock() {
            return (
                this.staticBlockById(
                    this.isManContext
                        ? PSB_PROMOTION_WIDGET_MEN
                        : PSB_PROMOTION_WIDGET_WOMEN
                ) || {}
            );
        },

        isManContext() {
            return this.isMan(this.$route.fullPath, this.$tPathKey(MENS));
        },

        isPromotionWidgetStaticBlock() {
            return (
                this.promotionWidgetStaticBlock &&
                !!Object.keys(this.promotionWidgetStaticBlock).length
            );
        },

        shouldShowWidget() {
            const { isBot = false } = this.$ua || {};

            if (!this.isPromotionWidgetEnabled || this.isMobile || isBot) {
                return false;
            }

            return this.isLoaded;
        },
    },

    watch: {
        shouldShowWidget: {
            immediate: true,
            handler() {
                if (this.shouldShowWidget) {
                    this.getPromotionWidgetStaticBlocks();
                }
            },
        },
    },

    async beforeMount() {
        this.handleScrollDelayedFn = throttle(this.handleScroll, SCROLL_DELAY);

        const isPromotionWidgetEnabledCookie = await this.$cookies.get(
            cookieKeyWithPrefix
        );

        if (typeof isPromotionWidgetEnabledCookie === 'undefined') {
            this.isPromotionWidgetEnabled = true;

            this.$cookies.set(cookieKeyWithPrefix, 'true', {
                path: '/',
            });
        } else {
            this.isPromotionWidgetEnabled = JSON.parse(
                isPromotionWidgetEnabledCookie
            );
        }
    },

    mounted() {
        this.addScrollListener();

        this.$nextTick(() => {
            this.isLoaded = true;
        });
    },

    beforeDestroy() {
        this.handleScrollDelayedFn.cancel();
        this.removeScrollListener();
    },

    beforeCreate() {
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.Close = Close;
    },

    methods: {
        ...mapCmsBlockActions(['getCmsBlocks']),

        async getPromotionWidgetStaticBlocks() {
            if (!Object.keys(this.promotionWidgetStaticBlock).length) {
                this.getCmsBlocks({
                    cmsBlocksToLoad: PROMOTION_WIDGET_BLOCK_IDS,
                });
            }
        },

        closePromotionWidget() {
            this.$cookies.set(cookieKeyWithPrefix, 'false', {
                path: '/',
            });

            this.isPromotionWidgetEnabled = false;
        },

        addScrollListener() {
            window.addEventListener('scroll', this.handleScrollDelayedFn);
        },

        removeScrollListener() {
            window.removeEventListener('scroll', this.handleScrollDelayedFn);
        },

        getDocumentTriggerHeight() {
            return document.body.scrollHeight * PART_HEIGHT_TRIGGER_ACTION;
        },

        handleScroll() {
            if (window.scrollY >= this.getDocumentTriggerHeight()) {
                this.isPositionTriggerHeight = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.promotion-widget {
    @apply z-6 fixed block top-0 bottom-auto left-0 w-full;

    .promotion-widget-block {
        @apply relative w-full h-full;
    }

    .close {
        @apply absolute right-0 top-[-48px];
    }

    :deep() {
        .static-block {
            @apply block w-full h-full;
        }

        .psb_promotion_widget_men,
        .psb_promotion_widget_women {
            .mobile {
                @apply block w-full h-full;

                .content {
                    height: theme(
                        'customVariables.promotion.widget.xxs.blockHeight'
                    );
                }
            }

            .desktop {
                @apply hidden;
            }
        }
    }

    @screen xs {
        @apply mx-auto;

        .promotion-widget-block {
            @apply mx-auto;

            max-width: theme(
                'customVariables.promotion.widget.xs.blockMaxWidth'
            );
        }

        :deep() {
            .static-block {
                @apply block w-full h-full;
            }

            .psb_promotion_widget_men,
            .psb_promotion_widget_women {
                .mobile {
                    @apply block h-full;

                    .content {
                        height: theme(
                            'customVariables.promotion.widget.xs.blockHeight'
                        );
                    }
                }
            }
        }
    }

    @screen md {
        @apply fixed block top-auto bottom-10 left-10;

        width: theme('customVariables.promotion.widget.md.blockWidth');
        height: theme('customVariables.promotion.widget.md.blockHeight');

        &:deep() .psb_promotion_widget_men,
        &:deep() .psb_promotion_widget_women {
            .mobile {
                @apply hidden;
            }

            .desktop {
                @apply block w-full h-full;

                .content {
                    height: theme(
                        'customVariables.promotion.widget.md.blockHeight'
                    );
                }
            }
        }
    }
}
</style>
